import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  methods: {
    getStatusColor(statusId, processType) {
      if (processType === feedbackEnums.processTypes.MAFEG) {
        if (statusId === 100) {
          return 'orange';
        } else if (statusId === 700 || statusId === 170) {
          return 'green';
        } else {
          return 'blue';
        }
      } else if (processType === feedbackEnums.processTypes.EHG) {
        if (statusId === 200) {
          return 'orange';
        } else if (statusId === 250 || statusId === 260) {
          return 'green';
        } else {
          return 'blue';
        }
      } else if (processType === feedbackEnums.processTypes.PDC) {
        if (statusId === 300) {
          return 'orange';
        } else if (statusId === 340) {
          return 'green';
        } else {
          return 'blue';
        }
      } else if (processType === feedbackEnums.processTypes.EDC) {
        if (statusId === 500) {
          return 'orange';
        } else if (statusId === 530 || statusId === 540) {
          return 'green';
        } else {
          return 'blue';
        }
      } else if (processType === feedbackEnums.processTypes.PROMOTION) {
        if (statusId === 410) {
          return 'orange';
        } else if (statusId === 420) {
          return 'green';
        } else {
          return 'blue';
        }
      } else {
        return 'blue';
      }
    }
  }
};
