<template>
  <v-dialog v-model="dialog" max-width="1200px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn small v-on="{ ...dialog }" v-bind="attrs">
        <slot name="button">
          <v-icon small class="mr-2">mdi-file-export</v-icon>
          Archiv öffnen
        </slot>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5"><slot name="header"></slot></span>
      </v-card-title>
      <v-card-text>
        <archive-table
          :feedbacks="processes"
          :loading="loading"
          :searchable="false"
          :isArchive="true"
          :employeeColumn="'supervisor'"
          :openInTab="true"
        ></archive-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          <slot name="close-button">{{ $t('close') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { v4 as uuid } from 'uuid';
import { feedbackEnums } from '@/enums/feedbacks.js';

import archiveTable from '@/components/feedbacks/archive-table.vue';

export default {
  props: {
    userId: {
      type: String,
      required: true
    },

    userShorthand: {
      type: String,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    processes: null,
    loading: false
  }),

  watch: {
    async dialog() {
      if (!this.dialog || this.processes) {
        return;
      }

      this.loading = true;

      const feedbackData = await this.listFeedbacks(this.userId);
      if (feedbackData) {
        this.processes = feedbackData.filter((x) => x.archived);
      }

      await this.setImportedArchives();

      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      listFeedbacks: 'feedbacks/listFeedbacksAsRecipient',
      importArchive: 'feedbacks/importArchive',
      getEmployee: 'users/fetchUserDetails'
    }),

    close() {
      this.dialog = false;
    },

    async setImportedArchives() {
      const employee = await this.getEmployee(this.userShorthand);
      const archiveData = await this.importArchive(this.userShorthand);

      if (archiveData.isAvailable) {
        this.processes.push({
          customArchive: true,
          archived: true,
          processType: feedbackEnums.processTypes.MAFEG,
          statusId: 170,
          startDate: archiveData.startDate,
          endDate: archiveData.endDate,
          donorFirstName: archiveData.donorFirstname,
          donorLastName: archiveData.donorLastname,
          talkDate: archiveData.talkDate,
          feedbacks: [
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.SELF_EVALUATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.selfEvaluationUrl,
              canRead: !employee.restrictedAccess
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.MANAGER_EVALUATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.supervisorEvaluationUrl,
              canRead: !employee.restrictedAccess
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.DOCUMENTATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.conclusionUrl,
              canRead: !employee.restrictedAccess
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.COMPARISON,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.comparisonUrl,
              canRead: !employee.restrictedAccess
            }
          ]
        });
      }
    }
  },

  components: {
    archiveTable
  }
};
</script>
